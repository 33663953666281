<template>
  <el-form
    ref="form"
    class="I18NLangForm"
    :model="form"
    :rules="isEmptyForm() && lang !== 'en' ? {} : rules"
    size="small"
    label-width="100px"
    label-position="left"
    @validate="handleValidate"
  >
    <el-form-item
      label="类目名称"
      prop="title"
    >
      <el-input
        v-model="form.title"
        placeholder="单行输入"
      />
    </el-form-item>
  </el-form>
</template>

<script>

export default {
  name: 'I18NLangForm',
  props: {
    form: {
      type: Object,
      default: () => {}
    },
    lang: {
      type: String,
      default: 'en'
    }
  },
  data () {
    return {
      rules: {
        title: [{ required: true, message: '类目名称必填' }]
      }
    }
  },
  methods: {
    isEmptyForm () {
      let isEmpty = true
      Object.keys(this.form).forEach(field => {
        if (this.form[field]) isEmpty = false
      })
      if (isEmpty && this.$refs.form) this.$refs.form.clearValidate()
      return isEmpty
    },
    // 用于父级组件校验本组件
    async validate () {
      return this.$refs.form.validate().then(valid => {
        if (valid) {
          return Promise.resolve()
        }
      })
    },
    // 用于向父级组件暴露校验失败信息
    handleValidate (field, valid) {
      if (!valid) {
        this.$emit('validateFail', this.lang)
      }
    }
  }
}
</script>
<style scoped lang="less">
  .I18NLangForm{
    .el-input,
    .el-textarea {
      width: 420px;
    }
  }
</style>
