import request from '@/utils/request'

export function queryCategoryList (data) {
  return request({
    url: '/activity/groupList',
    data
  })
}
export function downloadInviteCode (data) {
  return request({
    url: '/activity/resourceGameDownload',
    data
  })
}

export function resourceGameExport (data) {
  return request({
    url: '/activity/resourceGameExport',
    data
  })
}

export function deleteCategoryItem (data) {
  return request({
    url: '/activity/deleteGroup',
    data
  })
}

export function toggleCategoryItem (data) {
  return request({
    url: '/activity/updateGroupStatus',
    data
  })
}

export function queryCategoryItemInfo (data) {
  return request({
    url: '/activity/groupOne',
    data
  })
}

export function updateCategoryItemInfo (data) {
  return request({
    url: '/activity/groupSave',
    data
  })
}

export function queryCategoryItemList (data) {
  return request({
    url: '/activity/groupList',
    data
  })
}

export function toggleCategoryItemAD (data) {
  return request({
    url: '/activity/updateGroupStatus',
    data
  })
}

export function deleteCategoryItemAD (data) {
  return request({
    url: '/activity/deleteGroup',
    data
  })
}

export function updateCategoryItemADInfo (data) {
  return request({
    url: '/activity/groupSave',
    data
  })
}

export function queryCategoryItemADInfo (data) {
  return request({
    url: '/activity/groupOne',
    data
  })
}

export function queryAdList (data) {
  return request({
    url: '/activity/resource',
    data
  })
}
// export function GetActivityResource (id, params) {
//   return request({
//     url: `admin/activity_resource/${id}`,
//     params,
//     method: 'get',
//     baseURL: process.env.VUE_APP_BASEURL_API
//   })
// }
// 编辑
export function UpdateActivityResource (id, data) {
  console.log(id, data, 'data')
  return request({
    url: `admin/activity_resource/${id}`,
    data,
    method: 'put',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}
// 新增
export function CreateActivityResource (data) {
  console.log(data, 'data1')
  return request({
    url: 'admin/activity_resource',
    data,
    method: 'post',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}

export function queryADInfo (id, params) {
  return request({
    url: `admin/activity_resource/${id}`,
    params,
    method: 'get',
    baseURL: process.env.VUE_APP_BASEURL_API
  })
}

export function queryTypeOrderList (data) {
  return request({
    url: '/activity/groupList',
    data
  })
}

export function createADType (data) {
  return request({
    url: '/activity/groupSave',
    data
  })
}

export function updateADType (data) {
  return request({
    url: '/activity/groupSave',
    data
  })
}

export function updateAllADType (data) {
  return request({
    url: '/activity/updateMultipleGroup',
    data
  })
}

export function deleteAD (data) {
  return request({
    url: '/activity/deleteResource',
    data
  })
}

export function toggleAD (data) {
  return request({
    url: '/activity/resourceStatus',
    data
  })
}

export function updateOrSaveAdvertisement (data) {
  return request({
    url: '/activity/resourceSave',
    data
  })
}

export function verifyAdvertisingId (data) {
  return request({
    url: '/activity/verifyAdvertisingId',
    data
  })
}

export function getAdvertisingList (data) {
  return request({
    url: 'activity/getAdvertisingList',
    data
  })
}

export function getNationAreaList (data) {
  return request({
    url: 'user/nationAreaList',
    data
  })
}
