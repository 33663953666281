<template>
  <Layout>
    <div class="content">
      <h4 class="title">
        类目信息
      </h4>
      <el-form
        ref="form"
        v-loading="fetchLoading"
        :model="formData"
        :rules="rules"
        size="small"
        label-width="100px"
        label-position="left"
      >
        <el-form-item
          label="排序"
          prop="sort"
        >
          <el-input-number
            v-model="formData.sort"
            :min="0"
            label="排序"
          />
        </el-form-item>
        <el-form-item
          label="位置"
          prop="pId"
        >
          <el-select
            v-model="formData.pId"
            placeholder="位置"
            clearable
          >
            <el-option
              v-for="item in positionList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="描述"
        >
          <el-input
            v-model="formData.desc"
            :autosize="{ minRows: 3, maxRows: 5}"
            clearable
            type="textarea"
            placeholder="简要描述"
          />
        </el-form-item>
        <el-form-item
          label="状态"
          prop="status"
        >
          <el-switch
            v-model="formData.status"
            :active-value="1"
            :inactive-value="2"
            active-text="启用"
            inactive-text="禁用"
          />
        </el-form-item>
        <!-- 新添加 -->
        <!-- <el-form-item label="外显版本">
          <el-input
            v-model="formData.version[0]"
            style="width:91px"
          />
          <span style="padding: 0 6px">-</span>
          <el-input
            v-model="formData.version[1]"
            style="width:91px"
          />
        </el-form-item>
        <el-form-item
          label="面向国家"
        >
          <el-select>
            <el-option
              v-for="(regionTypeitem, regionTypekey) in regionTypeObj"
              :key="regionTypekey"
              :label="regionTypeitem "
              :value="regionTypekey"
            />
          </el-select>
        </el-form-item> -->
      </el-form>
      <h4 class="title title-2">
        多语言配置
      </h4>
      <el-tabs
        v-model="currentLang"
        type="card"
      >
        <el-tab-pane
          v-for="(item, index) in langList"
          :key="index"
          :label="item.name"
          :name="item.value"
        >
          <I18NLangForm
            ref="subForm"
            :key="index"
            :lang="item.value"
            :form="I18NFieldMap[item.value]"
            @validateFail="handleValidateFail"
          />
        </el-tab-pane>
      </el-tabs>
      <div class="ctrl-area">
        <el-button
          :loading="updateLoading"
          type="primary"
          @click="handleSubmit"
        >
          保存
        </el-button>
        <el-button @click="handleCancel">
          取消
        </el-button>
      </div>
    </div>
  </Layout>
</template>

<script>
import { positionList, langList } from '@/utils/selectOptions'
import { queryCategoryItemInfo, updateCategoryItemInfo } from '@/api/APP_Category'
import { mapState } from 'vuex'
import I18NLangForm from './components/I18NLangForm'
import { cloneDeep } from 'lodash'

const initLangForm = () => {
  return langList.reduce((maps, item) => {
    maps[item.value] = {}
    return maps
  }, {})
}
const regionTypeObj = {
  all: '全部国家',
  display: '部分国家显示',
  hide: '部分国家隐藏'
}
export default {
  name: 'CategoryEdit',
  components: { I18NLangForm },
  data () {
    return {
      positionList,
      langList,
      regionTypeObj,
      isCategoryEdit: true,
      formData: {
        style: 0,
        status: '2',
        level: 1
        // version: ['', '']
      },
      fetchLoading: false,
      rules: {
        key: [{ required: true, message: 'Key必填' }],
        sort: [{ required: true, message: '排序必填' }],
        pId: [{ required: true, message: '位置必选' }]
      },
      updateLoading: false,
      isValidateFail: false,
      currentLang: 'en',
      I18NFieldMap: initLangForm(),
      zoneId: 1
    }
  },
  computed: {
    ...mapState('app', ['appId', 'channel', 'pkgType'])
  },
  created () {
    this.isCategoryEdit = this.$route.name === 'CategoryEdit'
    this.formData.zoneId = Number(this.$route.query.zoneId)
    if (this.isCategoryEdit) {
      this.handleQueryCategoryItemInfo()
    }
  },
  methods: {
    handleQueryCategoryItemInfo () {
      const id = Number(this.$route.query.id)
      this.fetchLoading = true
      queryCategoryItemInfo({
        id,
        appId: this.appId,
        channel: this.channel
      })
        .then(res => {
          if (res.code === 200) {
            this.formData = res.data
            this.formData.zoneId = Number(this.$route.query.zoneId)
            this.langList.forEach(lang => {
              const title = this.formData.titleDetail[lang.value]
              this.I18NFieldMap[lang.value] = {
                title
              } || {}
            })
          }
        })
        .finally(() => {
          this.fetchLoading = false
        })
    },
    handleCheckAllForm () {
      const checkAll = (resolve) => {
        Promise.all(this.$refs.subForm.map(item => item.validate()))
          .then((valid) => {
            if (valid) {
              resolve()
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          }).catch(err => {
            console.log(err)
          })
      }
      return new Promise(resolve => {
        this.$refs.form.validate()
          .then(valid => {
            if (valid) {
              checkAll(resolve)
            } else {
              this.$message.error('校验未通过, 请检查表单后再试')
            }
          })
      })
    },
    handleSubmit () {
      this.handleCheckAllForm()
        .then(() => {
          this.updateLoading = true
          const formData = this.preFormatFormData()
          updateCategoryItemInfo({
            ...formData,
            appId: this.appId,
            channel: this.channel,
            pkgType: this.pkgType
          })
            .then(res => {
              if (res.code === 200) {
                this.$message.success('新建或者更新成功')
                this.$router.back()
              }
            })
            .finally(() => {
              this.updateLoading = false
            })
        })
    },
    handleCancel () {
      this.$router.back()
    },
    // 校验同级表单时, 切换到第一个报错的表单项
    handleValidateFail (lang) {
      if (this.isValidateFail) return
      this.isValidateFail = true
      setTimeout(() => {
        this.isValidateFail = false
        this.currentLang = lang
      })
    },

    // 上传前处理表单数据, 以符合接口预期
    preFormatFormData () {
      const formData = cloneDeep(this.formData)
      const I18NFieldMap = this.I18NFieldMap
      formData.titleDetail = Object.keys(I18NFieldMap).reduce((maps, lang) => {
        maps[lang] = I18NFieldMap[lang].title || ''
        return maps
      }, {})
      return formData
    }
  }
}
</script>

<style scoped lang="less">
  .content {
    margin: 50px 80px;

    h4.title {
      margin-bottom: 40px;

      &.title-2 {
        margin-top: 60px;
      }
    }

    .el-input,
    .el-textarea {
      width: 420px;
    }
    .ctrl-area{
      margin-top: 80px;
      .el-button{
        padding:10px  40px;
      }
    }
  }
</style>
